* {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: #4060a1;
}

.App-header {
  background-image: url("navbar.png");
  height: 75px;
  padding-top: 5px;
}

.App-logo {
  width: 200px;
}

#videoconferencia {
  padding: 5px;
}

#videoconferencia,
#videoconferencia iframe {
  height: 89vh;
}
